import { ContactUsFormValuesType } from '../../types/contactUsTypes';

export const contactUsApiRepository = {
  async sendData(values: ContactUsFormValuesType) {
    const baseURL = process.env.REACT_APP_API_URL;

    try {
      const response = await fetch(`${baseURL}auth/contactUs`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(values),
      });

      return response.json();
    } catch (error) {
      //error.message
      console.warn(error);

      throw new Error();
    }
  },
};
