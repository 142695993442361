import { Link } from 'react-router-dom';

import { routes } from '../routes';
import classes from './NotFoundScreen.module.scss';

const NotFoundScreen: React.FC = () => {
  return (
    <div className={classes.root}>
      <h3 className={classes.title}>404 - not found</h3>
      <Link className={classes.link} to={routes.HomePage.path}>
        На главную
      </Link>
    </div>
  );
};

export default NotFoundScreen;
