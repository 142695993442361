import { FC } from 'react';

import bottomDecoration from '../../assets/images/bottomDecoration.webp';
import contactMainImage from '../../assets/images/contact.webp';
import { DecoratedText } from '../../components/DecoratedText/DecoratedText';
import { PageTemplate } from '../../components/PageTemplate/PageTemplate';
import { useScrollReset } from '../../hooks/useScrollReset';
import ContactUsCard from '../../screens/contact-us/components/ContactUsCard/ContactUsCard';
import styles from './ContactPage.module.scss';

export const ContactPage: FC = () => {
  useScrollReset();
  return (
    <PageTemplate
      mainImage={contactMainImage}
      subtitle="For all general inquiries please contact us:"
      pageTitle="Contact"
    >
      <main className={styles.main}>
        <span className={styles.mainHeader}>
          <DecoratedText text="Address" />
        </span>
        <div className={styles.address}>
          <p>919 North Market Street,</p>
          <p>Wilmington, Delaware,</p>
          <p>19801</p>
          <p>support@arllecta.com</p>
        </div>
        <iframe
          title="Address"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1289.8168121614763!2d-75.54853490429349!3d39.74597012759018!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c6fd405e4bbbff%3A0xa44af1f522cee18e!2s919%20N%20Market%20St%2C%20Wilmington%2C%20DE%2019801%2C%20USA!5e0!3m2!1sen!2sru!4v1648027577198!5m2!1sen!2sru"
          width="650"
          height="300"
          style={{ border: '1px solid rgba(176, 176, 163, 1)' }}
          loading="lazy"
        ></iframe>
        <img className={styles.bottomDecoration} src={bottomDecoration} alt="" />
        <div className={styles.contactForm}>
          <ContactUsCard />
        </div>
      </main>
    </PageTemplate>
  );
};
