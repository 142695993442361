import { FC, useRef, useState } from 'react';

import { ManualNavigation } from '../../components/ManualNavigation/ManualNavigation';
import { PageTemplate } from '../../components/PageTemplate/PageTemplate';
import SelectLanguage from '../../components/SelectLanguage/SelectLanguage';
import { LANGUAGES } from '../../constants/languages';
import { useScrollObserver } from '../../hooks/useScrollObserver';
import { useScrollReset } from '../../hooks/useScrollReset';
import styles from './HelpPage.module.scss';

export const HelpPage: FC = () => {
  const [language, setLanguage] = useState(LANGUAGES.ru);
  const manualRef = useRef(null);
  const activeMenu = useScrollObserver(manualRef, language);
  useScrollReset();
  return (
    <PageTemplate>
      <h1 className={styles.h1}>Руководство пользователя</h1>
      <div className={styles.wrapper}>
        <ManualNavigation activeMenu={activeMenu} language={language} parentRef={manualRef} />
        <div className={styles.manualText} ref={manualRef}>
          <div className={styles.langSelect}>
            <SelectLanguage setLanguage={setLanguage} />
          </div>
          <section id="gettingStarted">
            <h3 className={styles.h3}>Системные требования</h3>
            <p className={styles.p}>
              Чтобы начать работу, установите приложение Imiti из официального магазина приложений Google Play. <br />
              Imiti поддерживает устройства с операционной системой Android 9.0 и выше.
            </p>
            <h3 className={styles.h3}>Начало работы</h3>
            <p className={styles.p}>
              Добро пожаловать в Imiti. Это ваш персональный мобильный консьерж с функцией облачного хранилища. Он
              поможет вам с легкостью находить информацию и решать множество ежедневных задач. Просто задайте ему вопрос
              или вызовите одну из команд.
            </p>
            <img src="userGuide/0.jpg" alt="" />
            <p className={styles.p}>
              Для начала работы с консьержем вам нужно авторизоваться в приложении. Нажмите на кнопку Log in и введите
              данные для входа в ваш аккаунт (e-mail и password). Если вы забыли пароль, воспользуйтесь функцией Reset
              password. Если у вас еще нет аккаунта, нажмите на кнопку “Sign in” на стартовом экране для перехода к
              этапу регистрации.
            </p>
          </section>
          <section id="signup">
            <h3 className={styles.h3}>Регистрация</h3>
            <p className={styles.p}>
              Это раздел для новых пользователей Imiti, у которых еще нет аккаунта. Регистрация состоит из нескольких
              шагов. На первой странице вам необходимо заполнить следующие поля с информацией для создания профиля:
            </p>
            <ul>
              <li>
                Email - будет использоваться для входа в аккаунт, после завершения регистрации на этот адрес придет
                письмо для подтверждения;
              </li>
              <li>
                User name - это имя консьерж будет использовать для обращения к вам, вы всегда сможете сменить его
                позднее в настройках;
              </li>
              <li>
                Password - пароль, который будет использоваться для входа. Чтобы защитить свой аккаунт, постарайтесь
                придумать сложный пароль - обязательно используйте не меньше 8 символов, в том числе цифры, специальные
                символы и хотя бы одну заглавную букву. Подтвердите пароль, введя его еще раз в поле “confirm password”.
              </li>
            </ul>
            <p className={styles.p}>
              После заполнения всех полей нажмите кнопку “Next” для перехода к следующему шагу.
            </p>
            <img src="userGuide/1.jpg" alt="" />
            <p className={styles.p}>
              На второй странице заполните дополнительную информацию о себе: дату рождения, пол и регион.
            </p>
            <p className={styles.p}>
              После этого поставьте галочку согласия с правилами сервиса и политикой приватности и затем нажмите “Create
              account”.
            </p>
            <img src="userGuide/2.jpg" alt="" />
            <p className={styles.p}>
              После выполнения всех шагов вы будете автоматически авторизованы в созданном аккаунте. Далее вы сможете
              использовать введенные e-mail и пароль для входа. Не забудьте проверить почту, туда пришло письмо со
              ссылкой для подтверждения аккаунта и инструкцией.
            </p>
          </section>
          <section id="passReset">
            <h3 className={styles.h3}>Сброс пароля</h3>
            <p className={styles.p}>
              Не расстраивайтесь, если вы забыли пароль от своей учетной записи. Его легко можно восстановить.
            </p>
            <p className={styles.p}>
              Чтобы восстановить доступ к аккаунту, нажмите на “Reset password” на экране авторизации. Далее введите
              e-mail, на который зарегистрирован ваш аккаунт, и нажмите “Send code”.
            </p>
            <img className={styles.wideImg} src="userGuide/3.png" alt="" />
            <p className={styles.p}>
              Вам на почту отправлено письмо с кодом для сброса пароля. Введите его на открывшемся экране, чтобы перейти
              к сбросу пароля.
            </p>
            <p className={styles.p}>
              Если вы сделали все правильно, то увидите экран для ввода нового пароля к аккаунту
            </p>
            <img className={styles.wideImg} src="userGuide/4.png" alt="" />
            <p className={styles.p}>
              Готово - доступ к учетной записи восстановлен. Теперь для входа используйте новый пароль.
            </p>
          </section>
          <section id="interface">
            <h3 className={styles.h3}>Интерфейс</h3>
            <p className={styles.p}>
              <b>Как начать разговор с консьержем</b>
            </p>
            <p className={styles.p}>Предусмотрены несколько способов начать разговор с Imiti:</p>
            <ul>
              <li>
                <b>В чате.</b> Откройте приложение. Нажмите на круглую анимированную кнопку оранжевого цвета в центре
                нижней панели. Когда круг увеличился, а анимация на нем стала заметнее, Imiti слушает вас. Задайте
                вопрос или произнесите команду.
                <br /> После активации записи откроется дополнительное меню, в котором вы можете перейти в режим ввода
                текста с клавиатуры.
              </li>
              <li>
                <b>Голосом при свернутом приложении IMITI.</b> Включите фоновое прослушивание в настройках. Теперь,
                чтобы обратиться к консьержу, просто скажите “Imiti” и произнесите нужную команду.
              </li>
              <li>
                <b>Через виджет при свернутом приложении IMITI.</b> Включите виджет в разделе Background work в
                настройках и нажмите на круглый значок на любом экране своего устройства, чтобы активировать Imiti.
                Произнесите нужную команду и консьерж поможет вам.
              </li>
            </ul>
            <img className={styles.wideImg} src="userGuide/5.jpg" alt="" />
          </section>
          <section id="commands">
            <h3 className={styles.h3}>Команды для IMITI</h3>
            <p className={styles.p}>
              Вы можете обратиться к Imiti за информацией или помощью. Ниже приведен список примеров поддерживаемых
              команд.
            </p>
            <p className={styles.p}>
              <b>Что можно спросить у Imiti с примером команд:</b>
            </p>
            <p className={styles.p}>
              <b>Взаимодействие с ОС</b>
            </p>
            <p className={styles.p}>
              Батарея. How much battery do I have? <br />
              Процессор. What is my phone cpu temperature? <br />
              Камера. I want to make a video. <br />
              Память. What's the free size of the memory on the device? <br />
              Дисплей. What is display frequency? <br />
              WiFi/Bluetooth. Is there Five gigahertz support? What is wifi signal level? <br />
              Системные приложения. Add phone contact. Make my phone quieter
            </p>
            <p className={styles.p}>
              <b>Операции с файлами</b>
            </p>
            <p className={styles.p}>
              Локальное и облачное хранилище. Move file. Create folder. <br />
              Облачное хранилище. Upload folder, download file, share file, view cloud space storage. <br />
              Стандартные команды для управления голосом. Yes/No. All right.
            </p>
            <p className={styles.p}>
              <b>Развлечения</b>
            </p>
            <p className={styles.p}>Музыка и видео. Play Rammstein on Spotify. Turn on PewDiePie on Youtube.</p>
            <p className={styles.p}>
              Также вы можете сделать запрос в поисковую систему - просто продиктуйте его Imiti без дополнительных
              команд. С полным списком команд можно ознакомиться в этом разделе.
            </p>
          </section>
          <section id="storage">
            <h3 className={styles.h3}>Раздел Storage</h3>
            <p className={styles.p}>
              В данном разделе вы можете управлять вашими файлами, размещенными в локальной памяти вашего устройства и в
              облачном хранилище Imiti. Во вкладке local storage вы видите все файлы, находящиеся в памяти вашего
              устройства. Imiti может помочь вам с их организацией - просто обратитесь с нужной командой. Переносите
              файлы из локального хранилища в облако и наоборот, удаляйте ненужные и раскладывайте по папкам, а также
              совершайте другие манипуляции с файлами при помощи голосовых команд.(Например: “Imiti, remove file”).
            </p>
            <p className={styles.p}>
              Для работы с файлами используются пошаговые сценарии консьержа, на каждом этапе Imiti задаст вам
              уточняющие вопросы.
            </p>
            <p className={styles.p}>Например, сценарий для перемещения файла будет выглядеть так:</p>
            <p className={styles.p}>
              User: Imiti, move file. <br />
              IMITI: Which one? (Откроется раздел Storage для выбора файлов: вы можете произнести название файла
              консьержу либо ввести его вручную с клавиатуры) <br />
              User: “File name” (произнесите название или введите в строку поиска) <br />
              IMITI: Here is what I found. ( Выводится список найденных файлов, подходящих по условиям поиска ) <br />
              User: *Нажимает на нужный файл в списке* <br />
              IMITI: File selected. Where to move? <br />
              User: *Нажимает на нужную папку* <br />
              IMITI: Move file to folder? <br />
              User: Yes.
            </p>
            <p className={styles.p}>
              После получения подтверждения выбранный файл переносится в указанную папку. Аналогичным образом будет
              выполнено копирование файла в другую папку и его удаление.
            </p>
            <div className={styles.flex}>
              <img src="userGuide/6.jpg" alt="" />
              <img src="userGuide/7.jpg" alt="" />
              <img src="userGuide/8.jpg" alt="" />
            </div>
            <p className={styles.p}>
              Помимо этого, вы можете пользоваться этой вкладкой, как обычным файловым менеджером без использования
              голосовых команд. Здесь есть удобный поиск, сортировка и возможность создания, переноса и удаления папок и
              файлов.
            </p>
            <div className={styles.flex}>
              <img src="userGuide/9.jpg" alt="" />
              <img src="userGuide/10.jpg" alt="" />
              <img src="userGuide/11.jpg" alt="" />
            </div>
            <p className={styles.p}>
              Каждый зарегистрированный пользователь приложения Imiti получает 1 Гб облачного хранилища бесплатно.
              Используйте его, чтобы работать с Imiti на разных девайсах. Подробнее о работе с Cloud Storage читайте в
              следующих разделах.
            </p>
          </section>
          <section id="scenarios">
            <h3 className={styles.h3}>Раздел Scenarios</h3>
            <p className={styles.p}>
              Вы можете создавать собственные сценарии – последовательность действий, которая будет выполняться при
              произнесении выбранного вами ключевого слова (trigger phrase). Перечень таких действий включает в себя
              возможность позвонить, отправить SMS-сообщение, сообщение в один из поддерживаемых мессенджеров (Telegram,
              WhatsApp и др.)
            </p>
            <p className={styles.p}>
              В разделе Scenarios вы увидите все созданные вами сценарии и сможете создать новые. Вы можете
              отредактировать или удалить любой из сценариев и воспользоваться поиском по ключевому слову. Нажмите на
              “+” в правом верхнем углу, чтобы создать сценарий. Подробнее о создании сценариев читайте в разделе -
              пользовательские сценарии.
            </p>
          </section>
          <section id="settings">
            <h3 className={styles.h3}>Настройки</h3>
            <p className={styles.p}>
              Чтобы перейти в раздел настроек, нажмите на вкладку с шестеркой в нижнем меню. Здесь вы можете настроить
              Imiti под себя, чтобы работать было еще удобнее.
            </p>
            <img src="userGuide/12.png" alt="" />
            <p className={styles.p}>
              <b>Настройки профиля</b>
            </p>
            <p className={styles.p}>
              Нажмите на плашку со своим именем пользователя и аватаркой для того, чтобы перейти в этот раздел. Здесь вы
              можете поменять аватар профиля и имя пользователя. В разделе <b>General</b> можно отредактировать или
              дополнить информацию, которую вы заполняли при регистрации аккаунта. А также здесь вы можете поделиться с
              Imiti своими интересами, чтобы она понимала вас лучше. Выберите раздел интересов, а затем отметьте то, что
              вам интересно из списка. В разделе <b>Security</b> размещены настройки конфиденциальности. Установите
              здесь защитный пин-код или смените данные для входа. Здесь же находится кнопка для выхода из аккаунта.
            </p>
            <div className={styles.flex}>
              <img src="userGuide/13.jpg" alt="" />
              <img src="userGuide/14.jpg" alt="" />
            </div>
            <p className={styles.p}>
              <b>App settings.</b>
            </p>
            <p className={styles.p}>
              В разделе <b>Mood</b> вы можете выбрать стиль общения Imiti с вами (Friendly, Neutral, etc.). <br />
              Второй раздел посвящен настройке <b>background work</b>. Включите функцию listening in the background,
              чтобы вызывать Imiti по имени, не заходя в приложение. Дополнительно вы можете добавить виджет Imiti на
              экран устройства - разместите его там, где вам удобно и обращайтесь по нажатию. Подключите другие
              приложения в разделе <b>integrations</b>, чтобы создавать быстрые сценарии, связанные с ними.
            </p>
            <p className={styles.p}>
              <b>Information.</b>
            </p>
            <p className={styles.p}>
              В этом разделе вы можете ознакомиться с документацией приложения: политикой приватности и правилами
              пользования.
            </p>
          </section>
          <section id="cloudStorage">
            <h3 className={styles.h3}>Cloud Storage</h3>
            <p className={styles.p}>
              Каждый зарегистрированный пользователь приложения Imiti получает 1 Гб облачного хранилища бесплатно. Файлы
              будут доступны к просмотру и скачиванию с любого телефона на базе Android, с которого вы войдете в
              аккаунт.
            </p>
            <p className={styles.p}>Загружайте файлы в хранилище следующими способами:</p>
            <ul>
              <li>Из основного чата IMITI по команде Upload/Add files.</li>
              <li>Из файлового менеджера по команде Upload/Add files или по нажатию иконки Plus.</li>
              <li>
                При передаче файлов из сторонних приложений в IMITI у вас появится опция “загрузить файл в облако?”
              </li>
            </ul>
            <p className={styles.p}>
              Файлы загружаются в корневую директорию облачного хранилища. <br />
            </p>
            <p className={styles.p}>
              Любым файлом, хранящимся в вашем облачном хранилище, вы можете по своему усмотрению поделиться с другими.
              Поделиться можно ссылкой на файл, которая имеет срок жизни 12 часов. По истечении этого периода ссылка
              станет недоступной для всех. Папки, которыми вы поделитесь с другими пользователями будут формироваться в
              .zip архив.
            </p>
            <p className={styles.p}>
              Чтобы узнать сколько свободного места осталось в вашем облаке, нажмите на три точки в правом верхнем углу
              раздела Storage или спросите об этом Imiti.
            </p>
            <img src="userGuide/15.jpg" alt="" />
            <p className={styles.p}>
              Для того чтобы загрузить файл в облако, произнесите IMITI одну из соответствующих команд и пройти по
              следующему сценарию:
            </p>
            <p className={styles.p}>
              User: Move file to cloud. <br /> IMITI: Which file do you want to upload? <br /> User: Filename. <br />{' '}
              IMITI: Here is what i found. (Выводится список подходящих по названию файлов) <br /> User: *Нажимает на
              подходящий файл* <br /> IMITI: Filename selected. <br /> User: Fine. <br /> IMITI: Filename uploaded.
            </p>
            <div className={styles.flex}>
              <img src="userGuide/16.jpg" alt="" />
              <img src="userGuide/17.jpg" alt="" />
              <img src="userGuide/18.jpg" alt="" />
            </div>
            <p className={styles.p}>
              IMITI поможет вам поделиться любым файлом через облачное хранилище через специальный сценарий:
            </p>
            <p className={styles.p}>
              User: Share File. <br />
              IMITI: Which one? <br />
              User: Filename (Можно ввести текстом в открывшемся окне Storage) <br />
              IMITI: Here is what i found. (Выводится список подходящих по названию файлов). <br />
              User: *Нажимает на подходящий файл* <br />
              IMITI: Filename selected. <br />
              User: Ok
            </p>
            <p className={styles.p}>
              Открывается окно в котором вы можете выбрать: просто скопировать ссылку в буфер обмена или поделиться
              файлом через какое-то приложение. Файлы из локального хранилища будут скопированы в облако для того чтобы
              ими делиться.
            </p>
            <div className={styles.flex}>
              <img src="userGuide/19.jpg" alt="" />
              <img src="userGuide/20.jpg" alt="" />
              <img src="userGuide/21.jpg" alt="" />
            </div>
          </section>
          <section id="userScenarios">
            <h3 className={styles.h3}>Пользовательские сценарии</h3>
            <p className={styles.p}>
              В этом разделе вы можете создать собственные сценарии для Imiti, чтобы расширить список команд консьержа.
              Сценарий - это последовательность действий, которую выполнит консьерж по заданной вами команде. Например
              вы можете задать команду “weekends”, по которой Imiti отправит вашим коллегам сообщение о том, что ваша
              рабочая неделя закончилась и включит вашу любимую песню.
            </p>
            <p className={styles.p}>
              Нажмите на кнопку “+ Create scenario” или “+” в правом верхнем углу, чтобы перейти к настройке <br />
              Далее добавьте нужное действие из списка предложенных. <br /> Если в качестве действия вы выбрали отправку
              сообщения или звонок, то далее нужно выбрать человека, которому адресовано действие, и ввести текст
              сообщения. Для воспроизведения медиа нужно выбрать соответствующий файл. <br />
              Вы можете добавить до 5 действий в один сценарий, все они выполнятся последовательно. <br />
              Последним шагом задайте фразу или слово, по которой консьерж должен выполнить заданный сценарий. Можно
              ввести ее текстом или произнести в микрофон устройства по нажатию кнопки. Обратите внимание: фраза или
              слово для вызова вашего сценария не должны совпадать с системными командами Imiti. <br />
              Сохраните сценарий.
            </p>
            <p className={styles.p}>Вот и все, теперь Imiti знает вашу новую команду</p>
            <p className={styles.p}>
              Все ваши сценарии будут доступны в этом разделе. Вы можете отредактировать или удалить их в любой момент.
            </p>
            <div className={styles.flex}>
              <img src="userGuide/22.jpg" alt="" />
              <img src="userGuide/23.jpg" alt="" />
            </div>
            <div className={styles.flex}>
              <img src="userGuide/24.jpg" alt="" />
              <img src="userGuide/25.png" alt="" />
              <img src="userGuide/26.jpg" alt="" />
            </div>
          </section>
          <section id="commandList">
            <h3 className={styles.h3}>Полный список команд консьержа</h3>
            <p className={styles.p}>
              <b>Батарея</b>
              <br />
              Получение уровня заряда батареи
            </p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>battery level</li>
                <li>level of battery</li>
                <li>battery status</li>
                <li>phone battery</li>
                <li>smartphone battery</li>
                <li>what is battery level</li>
                <li>what is battery status</li>
                <li>what is the level of phone battery</li>
                <li>what is level of battery</li>
                <li>what is the level of my battery</li>
              </ul>
              <ul>
                <li>what is the level of my phone battery</li>
                <li>what is level of my phone battery</li>
                <li>what is level of my battery</li>
                <li>what is level of phone battery</li>
                <li>battery percentage</li>
                <li>what percentage of battery</li>
                <li>what percentage of phone battery</li>
                <li>percentage of battery</li>
                <li>percentage of the battery</li>
              </ul>
            </div>
            <p className={styles.p}>Получение температуры батареи в цельсиях</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>battery temperature</li>
                <li>phone battery temperature</li>
                <li>smartphone battery temperature</li>
                <li>cellphone battery temperature</li>
                <li>cell phone battery temperature</li>
                <li>what is my phone battery temperature</li>
                <li>what is my battery temperature</li>
              </ul>
              <ul>
                <li>what is phone battery temperature</li>
                <li>what is battery temperature</li>
                <li>battery is hot</li>
                <li>my phone is hot</li>
                <li>is my phone hot</li>
                <li>is my battery overheating</li>
                <li>is my phone overheating</li>
              </ul>
            </div>
            <p className={styles.p}>Узнать, через сколько времени зарядится устройство при текущей нагрузке</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>charge process</li>
                <li>how long my phone will charge</li>
                <li>how long phone will charge</li>
                <li>how long smartphone will charge</li>
                <li>how long battery will charge</li>
                <li>what time is required to charge the phone?</li>
              </ul>
              <ul>
                <li>when will battery be charged?</li>
                <li>when will the battery be charged?</li>
                <li>when will the phone be charged?</li>
                <li>when will phone be charged?</li>
              </ul>
            </div>
            <p className={styles.p}>Тип/технология батареи</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>what's my battery?</li>
                <li>battery type</li>
                <li>battery's type</li>
                <li>battery technology</li>
                <li>type of the battery</li>
                <li>what is my battery type</li>
              </ul>
              <ul>
                <li>type of my battery</li>
                <li>type of my phone battery</li>
                <li>technology of the battery</li>
                <li>what is battery type</li>
                <li>what is my phone's battery type</li>
              </ul>
            </div>
            <p className={styles.p}>
              Состояние батареи (качественное) - cold, dead, good, overheat, over voltage, unknown, unspecified failure
            </p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>what's the current state of my battery?</li>
                <li>in what condition is my battery?</li>
                <li>what condition is my battery in?</li>
                <li>battery state</li>
                <li>current battery state</li>
                <li>is the battery in good condition?</li>
              </ul>
              <ul>
                <li>state of my battery</li>
                <li>the state of phone battery</li>
                <li>the state of my battery</li>
                <li>is the battery in bad condition?</li>
                <li>what is the current state of the battery?</li>
              </ul>
            </div>
            <p className={styles.p}>Отслеживание, подключено ли устройство к питанию</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>is my phone charging?</li>
                <li>is my charging cord connected?</li>
                <li>is it charging?</li>
                <li>is the power cable connected?</li>
                <li>power connected?</li>
              </ul>
              <ul>
                <li>is power connected</li>
                <li>power cable connected</li>
                <li>is charging cord connected</li>
                <li>charging cord connected</li>
                <li>is charging</li>
              </ul>
            </div>
            <p className={styles.p}>Открытие окна настроек подробного использования батареи</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>information about battery usage</li>
                <li>battery usage information</li>
                <li>information about phone battery usage</li>
                <li>information about my phone battery usage</li>
                <li>information about my battery usage</li>
              </ul>
              <ul>
                <li>battery usage</li>
                <li>battery usage window</li>
                <li>open battery usage</li>
                <li>show battery usage information</li>
                <li>open battery usage window</li>
              </ul>
            </div>
            <p className={styles.p}>
              <b>Процессор</b>
            </p>
            <p className={styles.p}>Производитель и модель процессора</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>who made cpu?</li>
                <li>who made processor?</li>
                <li>cpu model</li>
                <li>processor model</li>
                <li>Processor information</li>
                <li>Processor info</li>
                <li>what is my phone cpu model?</li>
                <li>Tell me about my phone processor</li>
              </ul>
              <ul>
                <li>Information about my phone processor</li>
                <li>Information about the phone processor</li>
                <li>what is cpu model?</li>
                <li>what is processor model?</li>
                <li>what is my phone processor model?</li>
              </ul>
            </div>
            <p className={styles.p}>Количество ядер процессора</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>processor cores number</li>
                <li>processor cores quantity</li>
                <li>cpu cores quantity</li>
                <li>cpu cores number</li>
                <li>processor cores count</li>
                <li>cpu cores</li>
                <li>processor cores</li>
                <li>what is Processor cores number</li>
                <li>what is Processor cores quantity</li>
                <li>what is cpu cores number</li>
              </ul>
              <ul>
                <li>what is cpu cores quantity</li>
                <li>how many cpu cores do phone have?</li>
                <li>how many cpu cores do my phone have?</li>
                <li>how many cores do phone have?</li>
                <li>how many cores do my phone have?</li>
                <li>how many cpu cores?</li>
                <li>how many cores</li>
              </ul>
            </div>
            <p className={styles.p}>Получение температуры процессора</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>cpu temperature</li>
                <li>processor temperature</li>
                <li>what is my phone processor temperature?</li>
                <li>what is my phone cpu temperature?</li>
                <li>what is my processor temperature?</li>
                <li>what is my cpu temperature?</li>
                <li>what is phone processor temperature?</li>
                <li>what is phone cpu temperature?</li>
              </ul>
              <ul>
                <li>what is processor temperature?</li>
                <li>what is cpu temperature?</li>
                <li>what is the temperature of phone's cpu?</li>
                <li>what is the temperature of phone's processor?</li>
                <li>what is the temperature of my phone's processor?</li>
                <li>what is the temperature of my phone's cpu?</li>
              </ul>
            </div>
            <p className={styles.p}>
              <b>Камера</b>
            </p>
            <p className={styles.p}>Получение значения максимального разрешения фотографии</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>photo resolution</li>
                <li>photo resolution megapixels</li>
                <li>maximum photo resolution</li>
                <li>what is the maximum photo resolution</li>
                <li>megapixels of photo resolution</li>
              </ul>
              <ul>
                <li>photo resolution maximum</li>
                <li>photo resolution megapixels maximum</li>
                <li>what is the photo resolution</li>
                <li>max photo resolution</li>
                <li>max photo resolution megapixels</li>
              </ul>
            </div>
            <p className={styles.p}>Получение значения разрешения модуля камеры в мегапикселях</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>camera resolution</li>
                <li>camera resolution megapixels</li>
                <li>maximum camera resolution</li>
                <li>what is the maximum camera resolution</li>
                <li>megapixels of camera resolution</li>
              </ul>
              <ul>
                <li>camera resolution maximum</li>
                <li>camera resolution megapixels maximum</li>
                <li>max camera resolution megapixels</li>
                <li>what is the max camera resolution</li>
                <li>max camera resolution</li>
              </ul>
            </div>
            <p className={styles.p}>Получение значения максимального разрешения видео</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>video resolution megapixels</li>
                <li>video resolution</li>
                <li>maximum video resolution</li>
                <li>what is the maximum video resolution</li>
                <li>video resolution maximum</li>
              </ul>
              <ul>
                <li>video resolution maximum in megapixels</li>
                <li>max video resolution megapixels</li>
                <li>max video resolution</li>
                <li>what is the max video resolution</li>
                <li>megapixels of video resolution</li>
              </ul>
            </div>
            <p className={styles.p}>Получение значения, во сколько раз можно приблизить картинку в камере программно</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>maximum zoom number</li>
                <li>how much can my phone zoom in</li>
              </ul>
            </div>
            <p className={styles.p}>Наличие вспышки/фонарика</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>any camera flash?</li>
                <li>any flash?</li>
                <li>camera flash?</li>
                <li>is there any camera flash?</li>
                <li>is there any flash?</li>
              </ul>
              <ul>
                <li>is there camera flash?</li>
                <li>is there flash?</li>
                <li>Do I have camera flash?</li>
                <li>does my phone have flash</li>
                <li>Does my phone have camera flash?</li>
              </ul>
            </div>
            <p className={styles.p}>Запуск приложения камеры в режиме фото</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>i want to make photo</li>
                <li>i wanna make photo</li>
                <li>take photo</li>
                <li>open camera to make photo</li>
                <li>want to make photo</li>
              </ul>
              <ul>
                <li>want to take photo</li>
                <li>photo camera</li>
                <li>make a photo</li>
                <li>i wanna take photo</li>
                <li>to photograph</li>
              </ul>
            </div>
            <p className={styles.p}>Запуск приложения камеры в режиме видео</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>i want to make video</li>
                <li>i wanna make video</li>
                <li>take video</li>
                <li>video camera</li>
                <li>i want to take video</li>
              </ul>
              <ul>
                <li>open camera to make video</li>
                <li>want to make video</li>
                <li>want to take video</li>
                <li>i wanna take video</li>
                <li>make a video</li>
              </ul>
            </div>
            <p className={styles.p}>
              <b>Память</b>
            </p>
            <p className={styles.p}>Объем оперативной памяти</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>what’s the capacity of my operative memory?</li>
                <li>ram size?</li>
                <li>what is my phone's ram size?</li>
                <li>what is my phone’s ram?</li>
                <li>what is phone’s ram?</li>
                <li>my operative memory</li>
              </ul>
              <ul>
                <li>what is phone’s ram size?</li>
                <li>operative memory</li>
                <li>random access memory size</li>
                <li>what is the phone's core memory?</li>
                <li>phone's core memory</li>
                <li>my phone's main memory size</li>
                <li>my phone's ram</li>
              </ul>
            </div>
            <p className={styles.p}>Объем свободной оперативной памяти</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>what's the capacity of spare operative memory?</li>
                <li>free ram</li>
                <li>free ram memory</li>
                <li>what is my phone's free ram?</li>
                <li>what is phone's free ram?</li>
              </ul>
              <ul>
                <li>my free operative memory</li>
                <li>my phone's free ram</li>
                <li>my phone's spare ram</li>
                <li>my phone's free operative memory</li>
                <li>what is my phone's free operative memory</li>
              </ul>
            </div>
            <p className={styles.p}>Объем внутренней памяти устройства</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>what's the size of the memory on the device?</li>
                <li>what is my phone's memory capacity?</li>
                <li>what is my phone's memory space?</li>
                <li>what is phone's internal memory space?</li>
              </ul>
              <ul>
                <li>the memory size of my device</li>
                <li>what is my memory capacity?</li>
                <li>total internal memory size</li>
                <li>my phone's memory size</li>
                <li>my device memory size</li>
                <li>memory size of my phone</li>
              </ul>
            </div>
            <p className={styles.p}>Объем свободной внутренней памяти устройства</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>what’s the free size of the memory on the device?</li>
                <li>free memory capacity</li>
                <li>what is my phone’s free memory capacity?</li>
                <li>what is my phone’s free memory space?</li>
                <li>spare memory size of my phone</li>
              </ul>
              <ul>
                <li>what is phone’s free internal memory space?</li>
                <li>what is my free memory capacity?</li>
                <li>free total internal memory size</li>
                <li>my phone's free memory size</li>
                <li>my device free memory size</li>
                <li>free memory size of my phone</li>
                <li>free memory size of my device</li>
                <li>spare memory size of my device</li>
              </ul>
            </div>
            <p className={styles.p}>Переход в настройки флеш накопителя</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>open card usage info</li>
                <li>open card usage settings</li>
                <li>open memory card usage info</li>
                <li>open memory card usage settings</li>
                <li>open memory card usage window</li>
                <li>open memory card usage information</li>
                <li>want to know more about memory card usage</li>
              </ul>
              <ul>
                <li>want open card usage settings</li>
                <li>card usage info</li>
                <li>card usage settings</li>
                <li>memory card usage info</li>
                <li>memory card usage settings</li>
                <li>memory card usage window</li>
                <li>memory card usage information</li>
              </ul>
            </div>
            <p className={styles.p}>Переход в окно информации о заполненности внутренней памяти устройства</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>memory usage information</li>
                <li>memory usage window</li>
                <li>internal storage settings</li>
                <li>memory usage settings</li>
                <li>settings internal storage</li>
              </ul>
              <ul>
                <li>open memory usage info</li>
                <li>open memory usage information</li>
                <li>memory usage info</li>
                <li>open internal storage settings</li>
                <li>open memory usage settings</li>
              </ul>
            </div>
            <p className={styles.p}>
              <b>Дисплей</b>
            </p>
            <p className={styles.p}>Диагональ дисплея в дюймах</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>Display diagonal</li>
                <li>What is phone's diagonal?</li>
                <li>What is my phone's diagonal?</li>
                <li>What is display diagonal?</li>
                <li>Screen diagonal</li>
              </ul>
              <ul>
                <li>What is screen's diagonal?</li>
                <li>My phone's diagonal</li>
                <li>My phone's display diagonal</li>
                <li>My phone's screen diagonal</li>
                <li>What is the screen's diagonal?</li>
              </ul>
            </div>
            <p className={styles.p}>Значение, сколько раз в секунду обновляется экран, измеряется в герцах</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>How many times does the screen update per second?</li>
                <li>Display frequency</li>
                <li>Display frequency hertz</li>
                <li>Display frequency in hertz</li>
                <li>What is display frequency?</li>
              </ul>
              <ul>
                <li>Display refresh rate</li>
                <li>display frequency rate</li>
                <li>screen frequency</li>
                <li>screen frequency hertz</li>
                <li>screen refresh rate</li>
                <li>screen frequency rate</li>
              </ul>
            </div>
            <p className={styles.p}>Поддержка режима VR (Virtual Reality)</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>Vr support</li>
                <li>Vr accessibility</li>
                <li>Does my phone support Virtual Reality?</li>
                <li>Virtual Reality support</li>
                <li>vr support in phone</li>
                <li>any vr</li>
              </ul>
              <ul>
                <li>Does my phone support VR?</li>
                <li>is vr support</li>
                <li>is my phone support vr</li>
                <li>is virtual reality support</li>
                <li>is my phone support virtual reality</li>
              </ul>
            </div>
            <p className={styles.p}>Переход в настройки дисплея</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>Display settings</li>
                <li>Open display settings</li>
                <li>Open display settings window</li>
                <li>Want to set my display</li>
                <li>display settings open</li>
              </ul>
              <ul>
                <li>display settings window open</li>
                <li>open phone display settings</li>
                <li>show display settings</li>
                <li>show display settings window</li>
                <li>set phone display</li>
              </ul>
            </div>
            <p className={styles.p}>Переход в настройки домашнего экрана устройства</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>Home screen settings</li>
                <li>Desktop settings</li>
                <li>My home screen settings</li>
                <li>To set home screen</li>
                <li>To set desktop screen</li>
              </ul>
              <ul>
                <li>Open desktop settings</li>
                <li>open home screen settings</li>
                <li>desktop settings window</li>
                <li>show desktop settings</li>
                <li>open desktop settings window</li>
              </ul>
            </div>
            <p className={styles.p}>
              <b>WiFi/Bluetooth</b>
            </p>
            <p className={styles.p}>Cостояние WIFI (подключено/отключено)</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>Wifi status</li>
                <li>What is wifi status</li>
                <li>What is wifi state</li>
                <li>What is my wifi status</li>
                <li>What is my wifi state</li>
              </ul>
              <ul>
                <li>What is phone’s wifi status</li>
                <li>What is phone’s wifi state</li>
                <li>What is my phone’s wifi status</li>
                <li>What is my phone’s wifi state</li>
                <li>Is wifi enabled?</li>
              </ul>
            </div>
            <p className={styles.p}>Cостояние Bluetooth (подключено/отключено)</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>Bluetooth status</li>
                <li>What is bluetooth status</li>
                <li>What is bluetooth state</li>
                <li>What is my bluetooth status</li>
                <li>What is my bluetooth state</li>
              </ul>
              <ul>
                <li>What is phone's bluetooth status</li>
                <li>What is phone's bluetooth state</li>
                <li>What is my phone's bluetooth status</li>
                <li>What is my phone's bluetooth state</li>
                <li>Is bluetooth enabled?</li>
              </ul>
            </div>
            <p className={styles.p}>Уровень сигнала Wifi</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>Wifi signal level</li>
                <li>Level of wifi signal</li>
                <li>What is level of wifi signal?</li>
                <li>wifi level</li>
                <li>wifi signal</li>
              </ul>
              <ul>
                <li>What is the level of wifi signal?</li>
                <li>What is wifi signal level?</li>
                <li>signal level of wifi</li>
                <li>show level of wifi signal</li>
                <li>what is signal level of wifi</li>
              </ul>
            </div>
            <p className={styles.p}>Поддержка 5 ГГц (да/нет)</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>Five gigahertz support</li>
                <li>Is the Five gigahertz support?</li>
                <li>Is there Five gigahertz support?</li>
                <li>Five gigahertz band support</li>
                <li>5 gigahertz support</li>
                <li>5 GHz support</li>
              </ul>
              <ul>
                <li>is the five GHz support</li>
                <li>is 5 GHz support</li>
                <li>Is 5 gigahertz band support</li>
                <li>5 gigahertz band support</li>
                <li>5 GHz band support</li>
              </ul>
            </div>
            <p className={styles.p}>Включение Bluetooth</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>Turn on bluetooth</li>
                <li>Bluetooth turn on</li>
                <li>Enable bluetooth</li>
                <li>activate bluetooth</li>
                <li>bluetooth on</li>
              </ul>
              <ul>
                <li>run bluetooth</li>
                <li>bluetooth run</li>
                <li>enable phone’s bluetooth</li>
                <li>bluetooth activate</li>
                <li>bluetooth enable</li>
              </ul>
            </div>
            <p className={styles.p}>Отключение Bluetooth</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>Turn off bluetooth</li>
                <li>Bluetooth turn off</li>
                <li>Disable bluetooth</li>
                <li>off bluetooth</li>
                <li>bluetooth off</li>
              </ul>
              <ul>
                <li>deactivate bluetooth</li>
                <li>bluetooth deactivate</li>
                <li>disable phone’s bluetooth</li>
                <li>phone’s bluetooth deactivate</li>
                <li>bluetooth disable</li>
              </ul>
            </div>
            <p className={styles.p}>Открытие окна настроек Bluetooth</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>Open bluetooth settings</li>
                <li>Bluetooth settings</li>
                <li>Bluetooth settings open</li>
                <li>Bluetooth settings open it</li>
                <li>Open bluetooth settings window</li>
              </ul>
              <ul>
                <li>bluetooth settings window</li>
                <li>show bluetooth settings</li>
                <li>bluetooth settings show</li>
                <li>bluetooth settings screen</li>
                <li>Open bluetooth settings screen</li>
              </ul>
            </div>
            <p className={styles.p}>Открытие окна настроек Wifi</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>Open wifi settings</li>
                <li>Wifi settings</li>
                <li>Wifi settings open</li>
                <li>Wifi settings open it</li>
                <li>Open wifi settings window</li>
              </ul>
              <ul>
                <li>wifi settings window</li>
                <li>show wifi settings</li>
                <li>wifi settings show</li>
                <li>show wifi settings window</li>
                <li>open wifi window</li>
              </ul>
            </div>
            <p className={styles.p}>
              <b>Системные приложения</b>
            </p>
            <p className={styles.p}>Открытие внутренней галереи устройства</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>Open gallery</li>
                <li>Want to open gallery</li>
                <li>open my gallery</li>
                <li>open my phone's gallery</li>
                <li>my phone gallery</li>
              </ul>
              <ul>
                <li>want to look at my gallery</li>
                <li>my gallery window</li>
                <li>show gallery</li>
                <li>show my gallery</li>
                <li>open my gallery window</li>
              </ul>
            </div>
            <p className={styles.p}>Запуск приложения Youtube</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>Open youtube</li>
                <li>start youtube</li>
                <li>launch youtube</li>
                <li>open Youtube application</li>
                <li>Want to watch youtube</li>
              </ul>
              <ul>
                <li>Youtube home page</li>
                <li>launch youtube application</li>
                <li>show youtube home page</li>
                <li>open Youtube home page</li>
                <li>Open youtube window</li>
              </ul>
            </div>
            <p className={styles.p}>Открытие окна будильников</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>open alarm window</li>
                <li>want to set alarm</li>
                <li>want to look at my alarms</li>
                <li>my alarms</li>
                <li>alarm settings</li>
              </ul>
              <ul>
                <li>show alarm window</li>
                <li>show my alarms</li>
                <li>show alarm settings</li>
                <li>open my alarms</li>
              </ul>
            </div>
            <p className={styles.p}>Открытие общих настроек устройства</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>General settings</li>
                <li>Open general settings</li>
                <li>Open general settings window</li>
                <li>Want to set my phone</li>
                <li>general settings open</li>
              </ul>
              <ul>
                <li>general settings window open</li>
                <li>set my phone</li>
                <li>show general settings</li>
                <li>want to set phone</li>
                <li>set phone</li>
              </ul>
            </div>
            <p className={styles.p}>Открытие окна добавления нового контакта</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>Add contact</li>
                <li>create contact</li>
                <li>Add phone contact</li>
                <li>Add new phone contact</li>
                <li>Add new contact</li>
              </ul>
              <ul>
                <li>Add a contact</li>
                <li>create phone contact</li>
                <li>create new phone contact</li>
                <li>create new contact</li>
                <li>create a contact</li>
              </ul>
            </div>
            <p className={styles.p}>Увеличение громкости устройства на один пункт</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>Phone volume increase</li>
                <li>Change phone volume increase</li>
                <li>Make phone louder</li>
                <li>Make my phone louder</li>
                <li>want to increase phone volume</li>
              </ul>
              <ul>
                <li>Increase phone volume</li>
                <li>Increase phone volume level</li>
                <li>want to set volume increase</li>
                <li>Set volume increase</li>
              </ul>
            </div>
            <p className={styles.p}>Уменьшение громкости устройства на один пункт</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>Phone volume decrease</li>
                <li>Change phone volume decrease</li>
                <li>decrease phone volume</li>
                <li>decrease phone volume level</li>
                <li>Make phone quieter</li>
              </ul>
              <ul>
                <li>Make my phone quieter</li>
                <li>want to decrease phone volume</li>
                <li>want to decrease phone volume level</li>
                <li>want to set volume decrease</li>
                <li>Set volume decrease</li>
              </ul>
            </div>
            <p className={styles.p}>Открытие окна контактов на устройстве</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>Open my contacts</li>
                <li>Want to look at my contacts</li>
                <li>The contacts window</li>
                <li>Open the contacts</li>
                <li>Open contacts</li>
              </ul>
              <ul>
                <li>Contacts show</li>
                <li>Show my contacts</li>
                <li>wanna look at my contacts</li>
                <li>open the contacts window</li>
                <li>Show the contacts</li>
              </ul>
            </div>
            <p className={styles.p}>Открытие окна загрузок на устройстве</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>What is downloaded on my phone</li>
                <li>What is downloaded on my device</li>
                <li>Open the downloads</li>
                <li>Open the downloads window</li>
                <li>Want to look at my downloads</li>
              </ul>
              <ul>
                <li>show my downloads</li>
                <li>look at my downloads</li>
                <li>phone downloads</li>
                <li>device downloads</li>
                <li>open my downloads</li>
              </ul>
            </div>
            <p className={styles.p}>Включение вспышки</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>turn on the flash</li>
                <li>turn on flash</li>
                <li>flash on</li>
                <li>flashlight on</li>
                <li>want to turn on the flash</li>
              </ul>
              <ul>
                <li>turn on flashlight</li>
                <li>want to flash on</li>
                <li>flashlight enable</li>
                <li>enable flashlight</li>
                <li>turn on the flashlight</li>
              </ul>
            </div>
            <p className={styles.p}>Выключение вспышки</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>turn off the flash</li>
                <li>turn off flash</li>
                <li>flash off</li>
                <li>flashlight off</li>
                <li>want to turn off the flash</li>
              </ul>
              <ul>
                <li>turn off flashlight</li>
                <li>want to flash off</li>
                <li>want to flashlight off</li>
                <li>deactivate the flashlight</li>
                <li>turn off the flashlight</li>
              </ul>
            </div>
            <p className={styles.p}>Увеличение яркости экрана</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>Increase display brightness</li>
                <li>Display brightness change increase</li>
                <li>Change display brightness increase</li>
                <li>Increase brightness</li>
                <li>Increase phone brightness</li>
              </ul>
              <ul>
                <li>Increase screen brightness</li>
                <li>My display is dark</li>
                <li>want to increase brightness</li>
                <li>want to increase phone brightness</li>
                <li>My phone display is dark</li>
              </ul>
            </div>
            <p className={styles.p}>Уменьшение яркости экрана</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>decrease display brightness</li>
                <li>Display brightness change decrease</li>
                <li>Change display brightness decrease</li>
                <li>decrease brightness</li>
                <li>decrease phone brightness</li>
              </ul>
              <ul>
                <li>decrease screen brightness</li>
                <li>My display is too bright</li>
                <li>My phone display is bright</li>
                <li>want to decrease brightness</li>
                <li>Make my display darker</li>
              </ul>
            </div>
            <p className={styles.p}>
              <b>Локальное и облачное хранилище</b>
            </p>
            <p className={styles.p}>Перемещение файлов</p>
            <ul>
              <li>Move file</li>
              <li>Move folder</li>
            </ul>
            <p className={styles.p}>Копирование файлов</p>
            <ul>
              <li>Copy file</li>
              <li>Copy folder</li>
            </ul>
            <p className={styles.p}>Открыть файл для просмотра</p>
            <ul>
              <li>Open file</li>
              <li>Open folder</li>
              <li>Open picture</li>
            </ul>
            <p className={styles.p}>Поделиться файлом</p>
            <ul>
              <li>Share file</li>
            </ul>
            <p className={styles.p}>Создать папку (файл создать нельзя)</p>
            <ul>
              <li>Create folder</li>
            </ul>
            <p className={styles.p}>Удаление файлов</p>
            <ul>
              <li>Remove file</li>
              <li>Remove folder</li>
              <li>Delete file</li>
              <li>Delete folder</li>
            </ul>
            <p className={styles.p}>Очистить папку</p>
            <ul>
              <li>Clear folder</li>
            </ul>
            <p className={styles.p}>Найти файл или папку</p>
            <ul>
              <li>Find file</li>
              <li>Find folder</li>
            </ul>
            <p className={styles.p}>Переименовать файл или папку</p>
            <ul>
              <li>Rename file</li>
              <li>Rename folder</li>
            </ul>
            <p className={styles.p}>Найти музыку в YouTube/Spotify/YandexMusic</p>
            <ul>
              <li>Play Rammstein in YouTube</li>
              <li>Play Rammstein in spotify</li>
              <li>Watch rammstein du hast in yandexmusic</li>
              <li>Turn on rammstein in apple music</li>
            </ul>
            <p className={styles.p}>
              <b>Только облачное хранилище</b>
            </p>
            <p className={styles.p}>Загрузить файлы в облако</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>upload files from the office folder to the cloud storage</li>
                <li>upload abs file to cloud storage</li>
                <li>upload folder friends to cloud storage</li>
                <li>penny folder technician in the cloud</li>
                <li>upload mp3 file from current folder to cloud storage</li>
                <li>add all photos to the cloud</li>
                <li>add the file to cloud storage</li>
                <li>transfer files from the nature folder to the cloud</li>
              </ul>
              <ul>
                <li>add a file called keyboard to the cloud</li>
                <li>add monitor folder to cloud storage</li>
                <li>upload photo from photos folder to cloud storage</li>
                <li>upload the book hunter stories to the book folder in the cloud storage</li>
                <li>upload a folder under the name of movies to the cloud storage</li>
                <li>file text move to cloud</li>
                <li>add cup folder to cloud storage</li>
              </ul>
            </div>
            <p className={styles.p}>Скачать файлы из облака</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>save from cloud</li>
                <li>download files from the cloud</li>
                <li>save files from cloud storage</li>
                <li>download all files from cloud storage</li>
                <li>upload the monitor folder to the device from the cloud</li>
                <li>transfer notepad file from cloud storage to phone memory</li>
                <li>download notepad file from cloud storage to device memory</li>
                <li>upload glasses file from cloud storage</li>
                <li>download all photos from the cloud to the device memory</li>
              </ul>
              <ul>
                <li>download all files from cloud storage</li>
                <li>upload a photo with the name of the cat to your device from the cloud</li>
                <li>save the file from the cloud storage to the device</li>
                <li>file box from cloud to device</li>
                <li>download the window from the cloud storage to your phone</li>
                <li>unload the movies folder from the cloud storage to the device memory</li>
              </ul>
            </div>
            <p className={styles.p}>Открыть облачное хранилище для просмотра</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>open cloud storage</li>
                <li>show files from cloud storage</li>
                <li>open the cloud</li>
                <li>show the files that are in the cloud</li>
                <li>open the cup folder in the cloud storage</li>
                <li>show all files in the cloud</li>
                <li>show the file keyboard in cloud storage</li>
              </ul>
              <ul>
                <li>what files are in the cloud storage</li>
                <li>show cloud storage</li>
                <li>show cloud storage</li>
                <li>view cloud storage</li>
                <li>show the monitor folder in the cloud</li>
                <li>you can view files in the cloud</li>
                <li>please show the files in the cloud</li>
                <li>cloud files</li>
              </ul>
            </div>
            <p className={styles.p}>Статус свободного места на диске</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>tell me how much space in the cloud storage</li>
                <li>how much cloud storage space</li>
                <li>cloud storage space</li>
                <li>how much memory in cloud storage</li>
                <li>there is memory in the cloud storage</li>
                <li>withdraw the amount of free space in the cloud storage</li>
                <li>find out the amount of cloud storage</li>
                <li>how much space is taken up in the cloud storage</li>
              </ul>
              <ul>
                <li>Is there a place in the cloud storage</li>
                <li>how much memory is left in the cloud storage</li>
                <li>there is still space in the cloud storage</li>
                <li>show memory in cloud storage</li>
                <li>tell us about free space in cloud storage</li>
                <li>cloud storage information</li>
              </ul>
            </div>
            <p className={styles.p}>
              <b>Действия в режиме работы с файлами</b>
            </p>
            <p className={styles.p}>Подтверждение действия</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>ok</li>
                <li>yes</li>
                <li>confirm</li>
                <li>fine</li>
              </ul>
              <ul>
                <li>agreeable</li>
                <li>all right</li>
                <li>alright</li>
                <li>good</li>
              </ul>
            </div>
            <p className={styles.p}>Закрыть режим сценария с файлами</p>
            <div className={styles.commandsBlock}>
              <ul>
                <li>exit</li>
                <li>quit</li>
                <li>cancel</li>
                <li>exit the application</li>
                <li>close the application</li>
                <li>close</li>
                <li>come out</li>
              </ul>
              <ul>
                <li>turn off</li>
                <li>shut down</li>
                <li>leave</li>
                <li>go out</li>
                <li>output</li>
                <li>escape</li>
              </ul>
            </div>
          </section>
        </div>
      </div>
    </PageTemplate>
  );
};
