import clsx from 'clsx';
import { useEffect, useState } from 'react';

import archive from '../../../../assets/images/filesType/archive.svg';
import audio from '../../../../assets/images/filesType/audio.svg';
import executive from '../../../../assets/images/filesType/executive.svg';
import image from '../../../../assets/images/filesType/image.svg';
import text from '../../../../assets/images/filesType/text.svg';
import unknown from '../../../../assets/images/filesType/unknown.svg';
import video from '../../../../assets/images/filesType/video.svg';
import { FileExtensionType } from '../../../../types/downloadTypes';
import classes from './FileExtension.module.scss';

interface IFileTypeProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  extension?: FileExtensionType;
}

const FileExtension: React.FC<IFileTypeProps> = ({ className, extension }) => {
  const [fileExtension, setFileExtension] = useState(unknown);

  useEffect(() => {
    switch (extension) {
      case 'text':
        setFileExtension(text);
        return;
      case 'image':
        setFileExtension(image);
        return;
      case 'audio':
        setFileExtension(audio);
        return;
      case 'video':
        setFileExtension(video);
        return;
      case 'archive':
        setFileExtension(archive);
        return;
      case 'executive':
        setFileExtension(executive);
        return;
      default:
        return;
    }
  }, [extension]);

  return (
    <div className={clsx(classes.root, className)}>
      <img className={classes.image} src={fileExtension} alt={extension} />
    </div>
  );
};

export default FileExtension;
