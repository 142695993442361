import clsx from 'clsx';

import classes from './Textarea.module.scss';

interface ITextareaProps
  extends React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {}

const Textarea: React.FC<ITextareaProps> = ({ className, placeholder, ...rest }) => {
  return <textarea placeholder={placeholder} className={clsx(classes.root, className)} {...rest} />;
};

export default Textarea;
