import { FC } from 'react';

import { LandingFooter } from '../LandingFooter/LandingFooter';
import { LandingHeader } from '../LandingHeader/LandingHeader';
import styles from './PageTemplate.module.scss';

interface PageTemplateProps {
  mainImage?: string;
  subtitle?: string | JSX.Element;
  pageTitle?: string | JSX.Element;
  poweredBy?: boolean;
  children: any;
}

export const PageTemplate: FC<PageTemplateProps> = ({ mainImage, subtitle, children, pageTitle, poweredBy }) => (
  <>
    <LandingHeader />

    {mainImage && (
      <div style={{ backgroundImage: `url(${mainImage})` }} className={styles.mainImage}>
        <span className={styles.pageTitle}>{pageTitle}</span>
        {poweredBy && (<span className={styles.poweredBy}>powered by Arllecta</span>)}
      </div>
    )}
    {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
    <div className={styles.content}>{children}</div>
    <LandingFooter />
  </>
);
