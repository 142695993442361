export const DESCRIPTION_BLOCK_TEXTS = [
  {
    header: 'Day Scenarios',
    texts: [
      'Our innovative "Scripts" option allows the user to trigger any action from sending messages to delivering food or business documents.',
      'The "Multiple Scenarios" option allows you to activate several actions at once in parallel. For example, create a letter to a business partner, send a notification to the secretary about an upcoming meeting, order a taxi to the airport and call a friend. And all this is triggered by one trigger word or phrase.',
    ],
  },
  {
    header: 'Safe Storage',
    texts: [
      'We provide each of our users with private storage for their own documents. If necessary, the user can increase the volume of this storage to the desired size. This vault is protected by the latest keyless NACA technology.',
      `Photos, video files, audio files, text documents, and other files can be stored and protected from access by third parties. We guarantee the privacy of our users' data!`,
    ],
  },
  {
    header: 'Voice Navigation',
    texts: [
      'Using our friendly voice assistant, each user will be able to find, edit, send, delete any files on their mobile phone, as well as find out any necessary information in a second, by simply asking a question.',
      'Find a file by name, find a contact in the phone book, create a new contact, monitor battery charge, connect to the nearest Wifi point, search for information on the Internet, and much more than our voice assistant can do.',
    ],
  },
];
