export const downloadApiRepository = {
  async fetchData(code: string) {
    const baseURL = process.env.REACT_APP_API_URL;

    try {
      const response = await fetch(`${baseURL}auth/linkByCode?code=${code}`);

      return response.json();
    } catch (error) {
      //error.message
      console.warn(error);

      throw new Error();
    }
  },
};
