import { FC } from 'react';

import aboutMainImage from '../../assets/images/about.webp';
import { DecoratedText } from '../../components/DecoratedText/DecoratedText';
import { PageTemplate } from '../../components/PageTemplate/PageTemplate';
import { MAIN_TITLE } from '../../constants/pageTemplate';
import styles from './AboutPage.module.scss';

export const AboutPage: FC = () => {
  return (
    <PageTemplate
      mainImage={aboutMainImage}
      subtitle="Dedicated to saving our client's daytime with the very fast service"
      pageTitle="About"
    >
      <main className={styles.about}>
        <span className={styles.sectionHeader}>
          <DecoratedText text={`About ${MAIN_TITLE}`} />
        </span>
        <p>
          The idea for this product was born when our founder, Egger Mielberg, was working remotely for a long time due
          to the covid-19 pandemic. He had to work very hard with large volumes of various documents on his smartphone.
          However, it was not possible to quickly find the required document, edit it and order something to eat at the
          same time on the smartphone. When he calculated how much time is spent on the daily routine, he was quite
          surprised, 37%. Then the idea of ​​creating a mobile concierge with the capabilities of a personal secretary
          but with the speed of a high-speed computer was born. ​
        </p>
        <br />
        <p>
          At the moment, we are launching the first release of our personal mobile concierge, with the ability to work
          with large volumes of both personal files and various mobile options on our clients' smartphones.
        </p>
      </main>
      <section className={styles.testimonials}>
        <span className={styles.sectionHeader}>
          <DecoratedText text="Testimonials" />
        </span>
        <div className={styles.flex}>
          <div className={styles.testimonial}>
            <p>
              “I really enjoy IMITI as this app helps me save me a lot of time spent on daily routine tasks, easily responds to my requests without the need to even open the app.”
            </p>
            <div className={styles.divider}></div>
            <p className={styles.testimonialName}>Sandra Tamworth</p>
          </div>
          <hr className={styles.hr} data-width="1" data-size="100" />
          <div className={styles.testimonial}>
            <p>
              “IMITI is very helpful and easy to navigate. I love the way I can manage files on my device with just my voice. Cloud storage is also very convenient, with 1 GB of space for my personal files.”
            </p>
            <div className={styles.divider}></div>
            <p className={styles.testimonialName}>Priscilla Upton</p>
          </div>
          <hr className={styles.hr} data-width="1" data-size="100" />
          <div className={styles.testimonial}>
            <p>
              “I love my assistant! Awesome features, typically gives relevant information when asked, very fast saving a lot of time by not having to sift through tons of results and a fast basic answer.”
            </p>
            <div className={styles.divider}></div>
            <p className={styles.testimonialName}>Andrew Carlisle</p>
          </div>
        </div>
        <div className={styles.flex}>
          <div className={styles.testimonial}>
            <p>
              “I'm excited about how much work the team has been putting into this app. To me It's very helpful when I need some "hands-free" user experience or if I quickly need to send a message when I am not completely able to use my device.”
            </p>
            <div className={styles.divider}></div>
            <p className={styles.testimonialName}>Andrea Rodriguez</p>
          </div>
          <hr className={styles.hr} data-width="1" data-size="100" />
          <div className={styles.testimonial}>
            <p>
              “I automated some repetitive actions and I can start them by saying my special keyword without opening the app. It listens in the background and swiftly responds. Terrific! Looking forward to more great features.”
            </p>
            <div className={styles.divider}></div>
            <p className={styles.testimonialName}>Amber Smith</p>
          </div>
        </div>
      </section>
    </PageTemplate>
  );
};
