import clsx from 'clsx';
import { FC } from 'react';

import homeMainImage from '../../assets/images/home.webp';
import { DescriptionBlock } from '../../components/DescriptionBlock/DescriptionBlock';
import { PageTemplate } from '../../components/PageTemplate/PageTemplate';
import { DESCRIPTION_BLOCK_TEXTS } from '../../constants/homePage';
import styles from './HomePage.module.scss';

export const HomePage: FC = () => {
  const homePageSubtitle = (
    <>
      Providing personalized, mobile, <span style={{ color: 'black', marginLeft: '6px' }}>instant assistance</span>
    </>
  );

  return (
    <PageTemplate mainImage={homeMainImage} subtitle={homePageSubtitle} poweredBy>
      <div className={styles.description}>
        <div className={clsx(styles.descriptionBlock, styles.hasDivider)}>
          <DescriptionBlock header={DESCRIPTION_BLOCK_TEXTS[0].header} texts={DESCRIPTION_BLOCK_TEXTS[0].texts} />
        </div>
        <hr className={styles.hr} />
        <div className={clsx(styles.descriptionBlock, styles.hasDivider)}>
          <DescriptionBlock header={DESCRIPTION_BLOCK_TEXTS[1].header} texts={DESCRIPTION_BLOCK_TEXTS[1].texts} />
        </div>
        <hr className={styles.hr} />
        <div className={styles.descriptionBlock}>
          <DescriptionBlock header={DESCRIPTION_BLOCK_TEXTS[2].header} texts={DESCRIPTION_BLOCK_TEXTS[2].texts} />
        </div>
      </div>
    </PageTemplate>
  );
};
