import clsx from 'clsx';

import LinkButton from '../../../../components/UI/LinkButton/LinkButton';
import { FileExtensionType } from '../../../../types/downloadTypes';
import { getParseSize } from '../../../../utils/getParseSize';
import FileExtension from '../FileExtension/FileExtension';
import classes from './DownloadCard.module.scss';

interface IDownloadCardProps {
  className: string;
  author?: string;
  name: string;
  size: string;
  extension?: FileExtensionType;
  url: string;
}

const DownloadCard: React.FC<IDownloadCardProps> = ({ className, author, name, extension, size, url }) => {
  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.body}>
        <FileExtension className={classes.fileType} extension={extension} />
        <div className={classes.metadata}>
          <p className={classes.message}>
            <span>{author || 'User'}</span> has shared a file with you!
          </p>

          <h2 className={classes.fileName} title={name}>
            {name.length > 90 ? name.substr(0, 90) + '...' : name}
          </h2>

          <span className={classes.size}>{getParseSize(size)}</span>
        </div>
      </div>
      <LinkButton fullWidth={true} href={url} download={name}>
        Download file
      </LinkButton>
    </div>
  );
};

export default DownloadCard;
