import { FC } from 'react';
import { Link } from 'react-router-dom';

import servicesMainImage from '../../assets/images/services.webp';
import { DecoratedText } from '../../components/DecoratedText/DecoratedText';
import { PageTemplate } from '../../components/PageTemplate/PageTemplate';
import { useScrollReset } from '../../hooks/useScrollReset';
import styles from './ServicesPage.module.scss';

export const ServicesPage: FC = () => {
  useScrollReset();
  return (
    <PageTemplate
      mainImage={servicesMainImage}
      subtitle="
        Realizing the highest caliber of innovative mobile services"
      pageTitle="Our Services"
    >
      <main className={styles.main}>
        <span className={styles.mainHeader}>
          <DecoratedText text="Excellence Guaranteed" />
        </span>
        <p>
          Our main innovative advantage is the execution of action scenarios related to various areas of business and
          personal life.
        </p>
        <br />
        <p>
          Our product supports both simple scenarios consisting of a single action and complex scenarios consisting of
          dozens of parallel or sequential actions.
        </p>
        <br />
        <p>The following are just some of the possible cases:</p>
      </main>
      <section className={styles.cases}>
        <div className={styles.flex}>
          <div className={styles.caseBlock}>
            <h2>Business Life</h2>
            <div className={styles.divider}></div>
            <p>Task scenarios </p>
            <p>Colleague scenarios </p>
            <p>Event scenarios </p>
            <p>Lunch scenarios </p>
            <p>Get-home scenarios </p>
          </div>
          <hr className={styles.hr} data-width="1" data-size="100" />
          <div className={styles.caseBlock}>
            <h2>Private Life</h2>
            <div className={styles.divider}></div>
            <p>Conversation scenarios </p>
            <p>Secret scenarios </p>
            <p>Family scenarios </p>
            <p>Kids scenarios </p>
          </div>
          <hr className={styles.hr} data-width="1" data-size="100" />
          <div className={styles.caseBlock}>
            <h2>Safe life</h2>
            <div className={styles.divider}></div>
            <p>Close-All scenarios </p>
            <p>Block-All scenarios </p>
            <p>Friend-Only scenarios </p>
            <p>Emergency scenarios </p>
            <p>Police scenarios </p>
          </div>
        </div>
        <Link to="/contact">
          <button className={styles.contactBtn}>Contact us now</button>
        </Link>
      </section>
    </PageTemplate>
  );
};
