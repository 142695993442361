import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import ContactUsReducer from '../modules/contact-us/ContactUsSlice';
import DownloadReducer from '../modules/download/DownloadSlice';

export const store = configureStore({
  reducer: {
    download: DownloadReducer,
    contactUs: ContactUsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
