import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../base/hooks/hooks';
import DownloadButton from '../../components/DownloadButton/DownloadButton';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import Loader from '../../components/UI/Loader/Loader';
import { fetchData } from '../../modules/download/DownloadSlice';
import classes from './DownloadScreen.module.scss';
import DownloadCard from './components/DownloadCard/DownloadCard';
import Phone from './components/Phone/Phone';
import UnavailableCard from './components/UnavailableCard/UnavailableCard';

const DownloadScreen: React.FC = () => {
  let { code } = useParams<{ code: string }>();
  const dispatch = useAppDispatch();

  const data = useAppSelector(state => state.download.data);
  const loading = useAppSelector(state => state.download.loading);

  //Effects
  useEffect(() => {
    dispatch(fetchData(code));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  //Renders
  if (loading) {
    return <Loader isAbsolute />;
  }

  return (
    <div className={classes.root}>
      <Phone />

      <div className={classes.content}>
        <Header />
        <main className={classes.main}>
          {data ? (
            <DownloadCard
              className={classes.card}
              author={data?.author}
              extension={data?.extension}
              size={data?.size}
              name={data?.name}
              url={data?.url}
            />
          ) : (
            <UnavailableCard className={classes.card} />
          )}

          <DownloadButton className={classes.download} />
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default DownloadScreen;
