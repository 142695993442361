import classes from './Loader.module.scss';

interface ILoaderProps {
  isAbsolute?: boolean;
}

const Loader: React.FC<ILoaderProps> = ({ isAbsolute = false }) => {
  const renderLoader = () => {
    return (
      <div className={classes.loader}>
        <div className={classes.inner}>
          <div className={classes.ldsRing}>
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      </div>
    );
  };

  if (isAbsolute) {
    return (
      <div className={classes.isAbsolute}>
        <div className={classes.overline}></div>
        {renderLoader()}
      </div>
    );
  }

  return <div className={classes.root}>{renderLoader()}</div>;
};

export default Loader;
