import phone from '../../../../assets/images/phoneBody.png';
import classes from './Phone.module.scss';

const Phone: React.FC = () => {
  return (
    <div className={classes.root}>
      <img className={classes.img} src={phone} alt="Mobile Application" />
    </div>
  );
};

export default Phone;
