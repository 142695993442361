import { FC } from 'react';
import { Link } from 'react-router-dom';

import styles from './DescriptionBlock.module.scss';

interface DescriptionBlockProps {
  texts: string[];
  header: string;
}
export const DescriptionBlock: FC<DescriptionBlockProps> = ({ texts, header }) => {
  return (
    <div className={styles.body}>
      <h1 className={styles.header}>{header}</h1>
      <div className={styles.divider}></div>
      {texts.map(text => (
        <p key={text}>{text}</p>
      ))}
      <Link className={styles.readMore} to="/our-services">{`Read more >>`}</Link>
    </div>
  );
};
