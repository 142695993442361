import clsx from 'clsx';

import classes from './Input.module.scss';

interface IInputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {}

const Input: React.FC<IInputProps> = ({ className, ...rest }) => {
  return <input className={clsx(classes.root, className)} {...rest} />;
};

export default Input;
