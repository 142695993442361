import { FC } from 'react';

import { COMPANY_ADDRESS, COMPANY_NAME } from '../../constants/footer';
import styles from './LandingFooter.module.scss';

export const LandingFooter: FC = () => {
  const year = new Date().getFullYear();
  return (
    <footer className={styles.footer}>
      <div className={styles.footerText}>
      <span>
        ​​​​© {year} {COMPANY_NAME}
      </span>
      <span>{COMPANY_ADDRESS}</span>
      </div>
    </footer>
  );
};
