import { RoutesType } from '../base/routes/types/RouteTypes';
import { DownloadPage } from '../pages/DownloadPage/DownloadPage';
import { HelpPage } from '../pages/HelpPage/HelpPage';
import { PolicyPage } from '../pages/PolicyPage/PolicyPage';
import { AboutPage } from './../pages/AboutPage/AboutPage';
import { ContactPage } from './../pages/ContactPage/ContactPage';
import { HomePage } from './../pages/HomePage/HomePage';
import { ServicesPage } from './../pages/ServicesPage/ServicesPage';
import DownloadScreen from './download/DownloadScreen';
import NotFoundScreen from './not-found/NotFoundScreen';
const pages = {
  HomePage,
  AboutPage,
  ServicesPage,
  ContactPage,
  PolicyPage,
  DownloadScreen,
  DownloadPage,
  NotFoundScreen,
  HelpPage
};

type RoutesKeys = keyof typeof pages;

export const routes: RoutesType<RoutesKeys> = {
  HomePage: {
    path: '/',
    exact: true,
    title: 'Home',
    component: HomePage,
  },
  AboutPage: {
    path: '/about',
    exact: true,
    title: 'About',
    component: AboutPage,
  },
  ServicesPage: {
    path: '/our-services',
    exact: true,
    title: 'Our Services',
    component: ServicesPage,
  },
  ContactPage: {
    path: '/contact',
    exact: true,
    title: 'Contact',
    component: ContactPage,
  },
  PolicyPage: {
    path: '/privacy-policy',
    exact: true,
    title: 'Privacy Policy',
    component: PolicyPage,
  },
  HelpPage: {
    path: '/help',
    exact: true,
    title: 'Help',
    component: HelpPage
  },
  NotFoundScreen: {
    path: '/not-found',
    exact: true,
    title: '404 - Not found',
    component: NotFoundScreen,
  },
  DownloadPage: {
    path: '/download-app',
    exact: true,
    title: 'Download App',
    component: DownloadPage
  },
  DownloadScreen: {
    path: '/:code',
    exact: true,
    title: 'Shared file',
    component: DownloadScreen,
  },
};
