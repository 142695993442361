import clsx from 'clsx';
import { FC } from 'react';

import { LANGUAGES } from '../../constants/languages';
import { scrollToItem } from '../../utils/scrollToItem';
import styles from './ManualNavigation.module.scss';

const pages = [
  { href: 'gettingStarted', ruLabel: 'Начало работы', enLabel: 'Getting started' },
  { href: 'signup', ruLabel: 'Регистрация' },
  { href: 'passReset', ruLabel: 'Сброс пароля' },
  { href: 'interface', ruLabel: 'Интерфейс' },
  { href: 'commands', ruLabel: 'Команды для IMITI' },
  { href: 'storage', ruLabel: 'Раздел Storage' },
  { href: 'scenarios', ruLabel: 'Раздел Scenarios' },
  { href: 'settings', ruLabel: 'Настройки' },
  { href: 'cloudStorage', ruLabel: 'Cloud Storage' },
  { href: 'userScenarios', ruLabel: 'Пользовательские сценарии' },
  { href: 'commandList', ruLabel: 'Полный список команд' },
];

const tagName = 'SECTION';
interface IProps {
  activeMenu: string;
  language: string;
  parentRef: React.MutableRefObject<any>;
}
export const ManualNavigation: FC<IProps> = ({ activeMenu, language, parentRef }) => {
  const onClick = (e: any) => {
    e.preventDefault();

    for (let section of parentRef.current.children) {
      if (section.tagName === tagName && section.id === e.target.getAttribute('href').replace('#', '')) {
        scrollToItem(section.offsetTop);
      }
    }
  };

  return (
    <aside className={styles.manualNavigation}>
      <ul className={styles.manualMenu}>
        {pages.map(({ href, enLabel, ruLabel }) => (
          <li className={clsx(styles.manualMenuItem, activeMenu === href ? styles.active : '')} key={href}>
            <a onClick={onClick} href={'#' + href}>
              {language === LANGUAGES.en ? enLabel : ruLabel ? ruLabel : enLabel}
            </a>
          </li>
        ))}
      </ul>
    </aside>
  );
};
