import clsx from 'clsx';

import classes from './LinkButton.module.scss';

interface IButtonProps
  extends React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
  fullWidth?: boolean;
}

const LinkButton: React.FC<IButtonProps> = ({ children, className, fullWidth = false, ...rest }) => {
  return (
    <a className={clsx(classes.root, className, fullWidth ? classes.fullWidth : '')} {...rest}>
      {children}
    </a>
  );
};

export default LinkButton;
