import Select, { components } from 'react-select';

import { LANGUAGES } from '../../constants/languages';

const selectLanguages = [
  { value: LANGUAGES.en, label: 'English', icon: '/gbr.svg', disabled: true },
  { value: LANGUAGES.ru, label: 'Russian', icon: '/ru.svg', disabled: false },
  { value: LANGUAGES.de, label: 'Deutsch', icon: '/ru.svg', disabled: true },
  { value: LANGUAGES.it, label: 'Italian', icon: '/ru.svg', disabled: true },
];

const SelectLanguage = ({ setLanguage }: any) => {
  const styles = {
    control: (css: any) => ({
      ...css,
      border: 'none',
      borderRadius: 0,
      boxShadow: 'none',
      backgroundColor: '#c7c7bd',
      fontFamily: 'Georgia',
      color: '#7c6d73',
      cursor: 'pointer',
      padding: '2px 0 2px 16px',
    }),
    valueContainer: (css: any) => ({
      ...css,
      fontSize: '18px',
      fontWeight: 500,
    }),
  };

  const onChange = (l: any) => setLanguage(l.value);

  const Control = ({ isDisabled, children, ...props }: any) => (
    <components.Control {...props}>
      {props.getValue()[0] ? (
        <span>
          <img src={props.getValue()[0].icon} alt="Icon" width={24} height={24} />
        </span>
      ) : null}
      {children}
    </components.Control>
  );

  return (
    <div className="select-wrapper">
      <Select
        isSearchable={false}
        instanceId="language"
        defaultValue={selectLanguages[1]}
        onChange={onChange}
        options={selectLanguages}
        isOptionDisabled={option => option.disabled}
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: '#7c6d73',
          },
        })}
        components={{ Control }}
        styles={{
          dropdownIndicator: css => ({
            ...css,
            color: '#7c6d73',
          }),
          indicatorSeparator: css => ({
            ...css,
            backgroundColor: '#7c6d7337',
          }),
          menu: css => ({
            ...css,
            borderRadius: 0,
          }),
          option: (css, { isDisabled }) => ({
            ...css,
            color: isDisabled ? '#858585' : '#f1f1e4',
          }),
          menuList: css => ({
            ...css,
            fontSize: '18px',
            fontWeight: 500,
            fontFamily: 'Georgia',
            backgroundColor: '#c7c7bd',
            color: '#7c6d73',
          }),
          ...styles,
        }}
      />
    </div>
  );
};

export default SelectLanguage;
