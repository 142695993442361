import { NavLink } from 'react-router-dom';

import logoDecoration from '../../assets/images/topDecoration.svg';
import { MAIN_TITLE } from '../../constants/pageTemplate';
import styles from './LandingHeader.module.scss';

export const LandingHeader: React.FC = () => {
  return (
    <header>
      <nav className={styles.topNav}>
        <input id={styles['menuToggle']} type="checkbox" />
        <label className={styles.menuButtonContainer} htmlFor={styles['menuToggle']}>
          <div className={styles.menuButton}></div>
        </label>
        <ul className={styles.menu}>
          <li>
            <NavLink className={styles.navLink} activeClassName={styles.navLinkActive} exact to="/">
              Home
            </NavLink>
          </li>
          <li>
            <NavLink className={styles.navLink} activeClassName={styles.navLinkActive} to="/about">
              About
            </NavLink>
          </li>
          <li>
            <NavLink className={styles.navLink} activeClassName={styles.navLinkActive} to="/our-services">
              Our Services
            </NavLink>
          </li>
          <li>
            <NavLink className={styles.navLink} activeClassName={styles.navLinkActive} to="/contact">
              Contact
            </NavLink>
          </li>
          <li>
            <NavLink className={styles.navLink} activeClassName={styles.navLinkActive} to="/download-app">
              Download App
            </NavLink>
          </li>
          <li>
            <NavLink className={styles.navLink} activeClassName={styles.navLinkActive} to="/privacy-policy">
              Privacy Policy
            </NavLink>
          </li>
          <li>
            <NavLink className={styles.navLink} activeClassName={styles.navLinkActive} to="/help">
              Help
            </NavLink>
          </li>
        </ul>
      </nav>
      <div className={styles.logoBlock}>
        <img className={styles.logoDecoration} src={logoDecoration} alt="" />
        <h1>{MAIN_TITLE}</h1>
        <h2>
          PERSONAL <strong>AI</strong> CONCIERGE
        </h2>
      </div>
    </header>
  );
};
