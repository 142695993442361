import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { routes } from '../../screens/routes';
import classes from './Footer.module.scss';

const Footer: React.FC = () => {
  const year = new Date().getFullYear();

  return (
    <footer className={classes.root}>
      <span className={classes.copyright}>© {year} IMITI</span>

      <Link className={clsx(classes.link, classes.first)} to={routes.PolicyPage.path}>
        Privacy policy
      </Link>
      <Link className={classes.link} to={routes.ContactPage.path}>
        Contact us
      </Link>
    </footer>
  );
};

export default Footer;
