import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { FileType } from '../../types/downloadTypes';
import { downloadApiRepository } from './DownloadApiRepository';

interface InitialState {
  loading: boolean;
  data: FileType | null;
}

const initialState: InitialState = {
  loading: false,
  data: null,
};

export const fetchData = createAsyncThunk('download/fetchData', async (code: string, { rejectWithValue }) => {
  const response = await downloadApiRepository.fetchData(code);

  if (response.error_code) {
    return rejectWithValue(response.error_text);
  } else if (response.message) {
    return rejectWithValue(response.message);
  }

  return response;
});

export const downloadSlice = createSlice({
  name: 'download',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchData.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchData.fulfilled, (state, action) => {
      state.loading = false;

      if (action.payload) {
        state.data = action.payload;
      }
    });
    builder.addCase(fetchData.rejected, (state, action) => {
      state.loading = false;

      console.log(action.payload);
    });
  },
});

export default downloadSlice.reducer;
