import clsx from 'clsx';

import googlePlay from '../../assets/images/googlePlay.png';
import classes from './DownloadButton.module.scss';

interface IDownloadProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  variant?: 'column' | 'row-reverse';
}

const DownloadButton: React.FC<IDownloadProps> = ({ className, variant, ...rest }) => {
  return (
    <div className={clsx(classes.root, variant === 'row-reverse' ? classes.rowReverse : '', className)} {...rest}>
      <p className={classes.text}>
        <a
          className={classes.link}
          href="https://play.google.com/store/apps/details?id=com.voiceassitant"
          target="_blank"
          rel="noreferrer"
        >
          Install IMITI now
        </a>{' '}
        to get your own cloud storage, file manager and smart voice assistant for FREE!
      </p>
      <a
        className={classes.app}
        href="https://play.google.com/store/apps/details?id=com.voiceassitant"
        target="_blank"
        rel="noreferrer"
      >
        <img src={googlePlay} alt="Download Google Play" />
      </a>
    </div>
  );
};

export default DownloadButton;
