import clsx from 'clsx';

import classes from './Button.module.scss';

interface IButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
    React.AriaAttributes {}

const Button: React.FC<IButtonProps> = ({ children, className, ...rest }) => {
  return (
    <button className={clsx(classes.root, className)} {...rest}>
      {children}
    </button>
  );
};

export default Button;
