import clsx from 'clsx';

import FileExtension from '../FileExtension/FileExtension';
import classes from './UnavailableCard.module.scss';

interface IUnavailableCardProps {
  className: string;
}

const UnavailableCard: React.FC<IUnavailableCardProps> = ({ className }) => {
  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.body}>
        <FileExtension className={classes.fileType} />
        <div className={classes.metadata}>
          <h2 className={classes.title}>File is unavailable</h2>
          <p className={classes.message}>
            Unfortunately, this link is no longer valid. Please contact file owner to get the actual link.
          </p>
        </div>
      </div>
    </div>
  );
};

export default UnavailableCard;
