import { FC } from 'react';

import leftDecoration from '../../assets/images/leftDecoration.webp';
import rightDecoration from '../../assets/images/rightDecoration.webp';
import styles from './DecoratedText.module.scss';

interface IProps {
  text: string;
}

export const DecoratedText: FC<IProps> = ({ text }) => {
  return (
    <div className={styles.decoratedText}>
      <img className={styles.decoration} src={leftDecoration} alt="" />
      <span className={styles.text}>{text}</span>
      <img className={styles.decoration} src={rightDecoration} alt="" />
    </div>
  );
};
