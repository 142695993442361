import { useState, useEffect } from 'react';

const options = {
  rootMargin: `-40% 0px -40% 0px`,
  threshold: 0,
};

export const useScrollObserver = (parentRef: React.MutableRefObject<any>, language: string) => {
  const [activeMenu, setActiveMenu] = useState('gettingStarted');

  const checkSupportObserver = () => {
    if (
      'IntersectionObserver' in window &&
      'IntersectionObserverEntry' in window &&
      'intersectionRatio' in window.IntersectionObserverEntry.prototype
    ) {
      return true;
    } else {
      console.error("IntersectionObserver don't supported");
      return false;
    }
  };

  useEffect(() => {
    if (!checkSupportObserver()) return;

    if (parentRef) {
      const sections = parentRef.current.children;

      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setActiveMenu(entry.target.id);
          }
        });
      }, options);

      for (let section of sections) {
        observer.observe(section);
      }

      return () => {
        for (let section of sections) {
          observer.unobserve(section);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]); // temp here

  return activeMenu;
};
