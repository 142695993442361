import { FC } from "react";
import { PageTemplate } from "../../components/PageTemplate/PageTemplate";
import downloadMainImage from "../../assets/images/download.webp"
import { DecoratedText } from "../../components/DecoratedText/DecoratedText";
import styles from "./DownloadPage.module.scss";
import screen1 from "../../assets/images/Scenario1.webp";
import screen2 from "../../assets/images/Scenario2.webp";
import screen3 from "../../assets/images/Scenario3.webp";
import screen4 from "../../assets/images/Scenario4.webp";
import googlePlayImg from "../../assets/images/googlePlay.png";
import { Link } from "react-router-dom";

export const DownloadPage: FC = () => {
  const pageTitle = (<span style={{ color: 'rgb(39,39,37)' }}>Download App</span>)
  return (
    <PageTemplate mainImage={downloadMainImage} subtitle="A solution to your daily routines is just a tap away" pageTitle={pageTitle}>
      <main className={styles.main}>
        <span className={styles.mainHeader}><DecoratedText text="Download IMITI" /></span>
        <div className={styles.screens}>
          <img src={screen1} alt="Imiti loading" />
          <img src={screen2} alt="Imiti scenarios" />
          <img src={screen3} alt="Imiti action selection" />
          <img src={screen4} alt="Imiti add trigger" />
        </div>
        <Link to="/help" className={styles.userGuide}>USER GUIDE</Link>
      </main>
      <section className={styles.downloadSection}>
        <div className={styles.downloadSectionContent}>
        <p className={styles.downloadText}>
          Install IMITI now to get your own AI concierge, cloud storage and file manager for Free! Available for all Android devices</p>
          <a className={styles.downloadLink} href="https://play.google.com/store/apps/details?id=com.voiceassitant">
        <img src={googlePlayImg} alt="Google Play button" />
          </a>
        </div>
      </section>
    </PageTemplate>
  )
}