import { useState, ChangeEvent, FormEvent } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../base/hooks/hooks';
import Button from '../../../../components/UI/Button/Button';
import Input from '../../../../components/UI/Input/Input';
import Textarea from '../../../../components/UI/Textarea/Textarea';
import { sendData } from '../../../../modules/contact-us/ContactUsSlice';
import { ContactUsFormValuesType } from '../../../../types/contactUsTypes';
import classes from './ContactUsCard.module.scss';

interface IContactUsCardProps {
  className?: string;
}

const ContactUsCard: React.FC<IContactUsCardProps> = ({ className, ...rest }) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(state => state.contactUs.loading);

  const [values, setValues] = useState<ContactUsFormValuesType>({
    username: '',
    email: '',
    contactNumber: '',
    message: '',
  });

  // Handlers
  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(sendData(values));
  };

  //Renders
  return (
    <>
      <form className={classes.form} onSubmit={handleSubmit}>
        <div className={classes.fields}>
          <div className={classes.inputs}>
            <div className={classes.inputSection}>
              <Input name="username" placeholder="Full Name" onChange={handleChange} value={values.username} required />
            </div>

            <div className={classes.inputSection}>
              <Input
                name="email"
                placeholder="Email"
                onChange={handleChange}
                value={values.email}
                type="email"
                required
              />
            </div>
            <div className={classes.inputSection}>
              <Input
                name="contactNumber"
                placeholder="Contact Number"
                onChange={handleChange}
                value={values.contactNumber}
                required
              />
            </div>
          </div>

          <div className={classes.textareaSection}>
            <Textarea
              className={classes.textarea}
              name="message"
              onChange={handleChange}
              value={values.message}
              required
              placeholder="Type your message here..."
            />
          </div>
        </div>

        <div className={classes.submitBtn}>
          <Button type="submit" disabled={loading === 'loading'}>
            Submit
          </Button>
          {loading === 'success' && <div className={classes.thanks}>Thanks for submitting!</div>}
        </div>
      </form>
    </>
  );
};

export default ContactUsCard;
