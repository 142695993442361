import clsx from 'clsx';
import { Link } from 'react-router-dom';

import logoNoSub from '../../assets/images/logoNoSub.svg';
import { routes } from '../../screens/routes';
import classes from './Header.module.scss';

interface IHeaderProps {
  center?: boolean;
  className?: string;
}

const Header: React.FC<IHeaderProps> = ({ center, className }) => {
  return (
    <header className={clsx(classes.root, className, center ? classes.center : '')}>
      <Link className={classes.link} to={routes.HomePage.path}>
        <img className={classes.logo} src={logoNoSub} alt="IMITI Logo" />
      </Link>
    </header>
  );
};

export default Header;
