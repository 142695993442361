import { FC } from 'react';

import { PageTemplate } from '../../components/PageTemplate/PageTemplate';
import PrivacyPolicyCard from '../../screens/privacy-policy/components/PrivacyPolicyCard/PrivacyPolicyCard';

export const PolicyPage: FC = () => {
  return (
    <PageTemplate>
      <PrivacyPolicyCard />
    </PageTemplate>
  );
};
