import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { ContactUsFormValuesType } from '../../types/contactUsTypes';
import { contactUsApiRepository } from './ContactUsApiRepository';

interface InitialState {
  loading: 'idle' | 'loading' | 'success' | 'error';
}

const initialState: InitialState = {
  loading: 'idle',
};

export const sendData = createAsyncThunk(
  'contactUs/sendData',
  async (values: ContactUsFormValuesType, { rejectWithValue }) => {
    values = {
      ...values,
      message: `Contact number:  ${values.contactNumber} 
      ${values.message} `
    }
    const response = await contactUsApiRepository.sendData(values);

    if (response.error_code) {
      return rejectWithValue(response.error_text);
    } else if (response.message) {
      return rejectWithValue(response.message);
    }

    return response.data;
  },
);

export const contactUsSlice = createSlice({
  name: 'contactUs',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(sendData.pending, (state, action) => {
      state.loading = 'loading';
    });
    builder.addCase(sendData.fulfilled, (state, action) => {
      state.loading = 'success';
    });
    builder.addCase(sendData.rejected, (state, action) => {
      state.loading = 'error';

      console.log(action.payload);
    });
  },
});

export default contactUsSlice.reducer;
